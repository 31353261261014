import React, { FC } from 'react';
import styles from './select-browser.module.scss';
import { Link } from '~/shared/link';
import { Portal } from '~/shared/portal/';
import { isIE } from '~/helpers/is-ie.helper';
import useTranslations from '../hooks/use-translations.hook';
import RawHtml from '~/page-elements/raw-html/raw-html.component';

const SelectBrowser: FC = () => {
    const translate = useTranslations();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (isIE) return null;

    const description = translate(
        'selectBrowser.description',
        `<p>Du har åbnet FTZ’s katalog i en browser som desværre ikke supporteres</p>
      <p>Åben linket i en anden browser</p>`
    );

    return (
        <Portal>
            <div className={styles.overlay}>
                <div className={styles.content}>
                    <RawHtml className={styles.description} content={description} />

                    <ul className={styles.browsersList}>
                        <li>
                            <Link href="https://www.google.com/chrome" target="_blank">
                                Google Chrome
                            </Link>
                        </li>
                        <li>
                            <Link href="https://www.microsoft.com/en-us/edge" target="_blank">
                                Microsoft Edge
                            </Link>
                        </li>
                        <li>
                            <Link href="https://www.mozilla.org/en-US/firefox/" target="_blank">
                                Firefox
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </Portal>
    );
};

export default SelectBrowser;
