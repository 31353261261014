import React, { useEffect } from 'react';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import useIOSViewport from '~/init/use-ios-viewport-height';
import { IPageTypes } from '~/models/dev.d';
import { Umbraco } from '~/models/umbraco.d';
import SelectBrowser from '~/shared/select-browser/select-browser.component';
import { useLayoutDispatch, LayoutActionTypes } from '~/context/layout.context';
import { usePage } from '~/libs/queries/bff';
import { CookieConsent } from '~/shared/scripts/cookie-consent';

const pageTypes: IPageTypes = {
    // List of page types from CMS
    [Umbraco.PagesTypes.StandardPage]: dynamic(() => import('./standard-page/standard-page.component')),
    [Umbraco.PagesTypes.ContentPage]: dynamic(() => import('./content-page/content-page.component')),
    [Umbraco.PagesTypes.RootPage]: dynamic(() => import('./root-page/root-page.component')),
    [Umbraco.PagesTypes.ErrorPage]: dynamic(() => import('./content-page/content-page.component')),
};

export const PageRender = () => {
    const { data: pageContent } = usePage();
    const nameOfPageType = pageContent?.contentTypeAlias;
    const PageType = pageTypes[nameOfPageType as string] ?? pageTypes[Umbraco.PagesTypes.StandardPage];
    const router = useRouter();
    const layoutDispatch = useLayoutDispatch();

    const startFetching = () =>
        layoutDispatch({
            type: LayoutActionTypes.SetLoading,
            payload: true,
        });

    const stopFetching = () =>
        layoutDispatch({
            type: LayoutActionTypes.SetLoading,
            payload: false,
        });

    useEffect(() => {
        router.events.on('routeChangeStart', startFetching);
        router.events.on('routeChangeComplete', stopFetching);
        return () => {
            router.events.off('routeChangeStart', startFetching);
            router.events.off('routeChangeComplete', stopFetching);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        layoutDispatch({
            type: LayoutActionTypes.setContentTypeAlias,
            payload: nameOfPageType as string,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nameOfPageType]);

    // Get the real viewport height from buggy iOS browsers
    useIOSViewport();

    useEffect(() => {
        if (!pageContent?.id) {
            return;
        }
        layoutDispatch({
            type: LayoutActionTypes.SetPageId,
            payload: pageContent?.id,
        });
    }, [pageContent?.id, layoutDispatch]);

    return (
        <>
            <Head>
                <title key="title">{pageContent?.metaTitle || pageContent?.name || 'FTZ'}</title>
                <meta name="buildinfo" content={process.env.NEXT_PUBLIC_BUILD_ID} />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover, shrink-to-fit=no"
                    key="viewport"
                />
                {/* Hide website from search engines, maybe change this in future when site is available without login?  */}
                <meta name="googlebot" content="noindex" />
                <meta name="robots" content="noindex" />
            </Head>

            <SelectBrowser />
            <PageType />

            <CookieConsent />
        </>
    );
};
