import React, { ReactElement, useEffect } from 'react';
import { GetStaticProps } from 'next';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { PageRender } from '~/page-types/page.render';
import { DynamicLayout } from '~/page-types/dynamic.layout';
import { queryResolver } from '~/libs/queries/query-resolver';
import { DynamicPageProps, NextPageWithLayout } from '~/page-types/types';
import { minutes } from '~/helpers/time.helper';
import { GA4PageNotFound } from '~/libs/ga4';

export const getStaticProps: GetStaticProps<DynamicPageProps> = async ({ locale }) => {
    const queryClient = new QueryClient();
    await queryResolver({
        queryClient,
        locale,
        slug: ['404'],
        forceFetchStatic: true,
    });
    const dehydratedState = dehydrate(queryClient);
    queryClient.clear();

    return {
        revalidate: minutes(10, true),
        props: {
            dehydratedState,
        },
    };
};

const Custom404: NextPageWithLayout<DynamicPageProps> = () => {
    const { events, asPath } = useRouter();

    const trackPage = () => {
        // We are only using timeout because the event must not be called before the pageview
        setTimeout(() => {
            GA4PageNotFound(window.location.href, window.document.referrer);
        }, 400);
    };

    useEffect(() => {
        // `routeChangeComplete` won't run for the first page load unless the query string is
        // hydrated later on, so here we log a event if this is the first render and
        // there's no query string
        if (!asPath.includes('?')) {
            trackPage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Will only run if there is a query string in the url on first render
        events.on('routeChangeComplete', trackPage);

        return () => {
            events.off('routeChangeComplete', trackPage);
        };
    }, [events]);

    return <PageRender />;
};

Custom404.getLayout = function getLayout(page: ReactElement) {
    return <DynamicLayout>{page}</DynamicLayout>;
};

export default Custom404;
