import dynamic from 'next/dynamic';
import { FunctionComponent, ReactNode } from 'react';
import { usePage, useStaticContent } from '~/libs/queries/bff';
import { ICmsPageStatusCode, IPageTypes } from '~/models/dev.d';
import { Umbraco } from '~/models/umbraco.d';
import useUrlHashHandler from '~/shared/hooks/use-url-has-handler';
import Loader from '~/shared/loader/loader.component';
import Custom404 from '~/pages/404';

const layouts: IPageTypes = {
    root: dynamic(() => import('~/shell/layouts/root')),
    standard: dynamic(() => import('~/shell/layouts/standard')),
    notLoaded: dynamic(() => import('~/shell/layouts/not-loaded')),
};

const getLayout = (nameOfPageType: string) => {
    switch (nameOfPageType) {
        case Umbraco.PagesTypes.RootPage:
        case Umbraco.PagesTypes.ErrorPage:
            return layouts.root;

        case Umbraco.PagesTypes.ContentPage:
        case Umbraco.PagesTypes.StandardPage:
            return layouts.standard;

        default:
            return layouts.standard;
    }
};

const isPageNotFound = (pageContent: ICmsPageStatusCode) => pageContent?.url === '/404/';

export const DynamicLayout: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
    const { data: pageContent } = usePage();
    const { isFetched, isSuccess } = useStaticContent();
    const nameOfPageType = pageContent?.contentTypeAlias;
    const Layout = getLayout(nameOfPageType as string);
    useUrlHashHandler(isFetched && isSuccess);

    // Always wait for static content to be fetched
    if (!isFetched && !isSuccess) {
        return <Loader fixed pageTitle="Loading..." />;
    }

    if (isPageNotFound(pageContent as ICmsPageStatusCode)) {
        return <Custom404 />;
    }

    return <Layout>{children}</Layout>;
};
